import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MdLocationOn} from 'react-icons/md';

const AnyReactComponent = ({ text }) => (
  <div>
  <MdLocationOn size={30} color="#fc7e59" />
  </div>
);

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 1.468581,
      lng: 103.616768
      , 
    },
    zoom: 15
  };

  render() {
    return (
      <div class="google-map" >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAoa_uGKN2lMY__Uz07-nwWdW7Yu7jNEqs' }}
          
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={1.468300}
            lng={103.616792}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
export default GoogleMap;
// CONTACT US PAGE

import React from "react"
import  "../styles/global.scss"
import Layout from "../layouts/index"
import GoogleMap from "../components/Map";
// import './bootstrap.min.css';
// import { Jumbotron, Button } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';
import { FaBuilding, FaPhone,FaEnvelope, FaClock, FaFacebook} from 'react-icons/fa';
import { IoLogoWhatsapp, IoIosAnalytics} from "react-icons/io";
import { Helmet } from 'react-helmet';

import "../../node_modules/bootstrap/dist/css/bootstrap.css";

const Contact = () => (  
   <Layout> 
       <Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | Contact Us</title>
          <meta name="title" content="Interaid Pharmacy | Contact Us" />
            <meta name="description" content="Operating hours: Tuesday - Sunday: 10.00am to 9.30pm (Closed on Monday)" />
          <link rel="canonical" href="www.interaidpharmacy.com.my/contact" />

        </Helmet>
        <Container fluid="true" className="page-title-block contact-page-title-block">
            <Row>
                <span className="text-center font-dark text-bold-500 text-white">Contact Us</span>
            </Row>
        </Container>
        
        <Container fluid="true" className="page-block">
            <div className="text-center">
                <IoIosAnalytics size="2em" color="#6d886f"/>
                <br/><br/>
                <p className="page-summary font-dark text-bold-500">We'd love to hear from you. Here's how to reach us.</p>
                <hr className="title-divider"/>
                <br/>
            </div>
            <Row>
                <Col md="7" sm="7">
                    <GoogleMap/>
                </Col>

                <Col md={{ size: 4, offset: 1 }} sm={{ size: 4, offset: 1 }}>
                         

                <p class="font-dark text-bold-500">Contact details</p>
                <p class="text-black thin-p">Interaid Pharmacy</p>
                <span/>
                    <Row>
                        <Col sm="1" xs="1"><FaBuilding color="#fc7e59"/> </Col>
                        <Col sm ="11" xs="11">51, Jalan Sentral 2, <br/>
                        Taman Nusa Sentral 79100 <br/>
                        Iskandar Puteri, Johor.
                        </Col>
                    </Row>
                

                <p className="thin-p">
                    <Row>
                        <Col sm="1" xs="1"><FaPhone /></Col>
                        <Col sm ="11" xs="11">07-5950581</Col>
                    </Row>
                </p>

                <p className="thin-p">
                    <Row>
                        <Col sm="1" xs="1"><IoLogoWhatsapp /></Col>
                        <Col sm ="11" xs="11">017-7679917</Col>
                    </Row>
                </p>
                
                <p className="thin-p">
                    <Row>
                        <Col sm="1" xs="1"><FaEnvelope /> </Col>
                        <Col sm ="11" xs="11">interaidpharmacy@hotmail.com</Col>
                    </Row>
                </p>

                <p class="thin-p">
                    <Row>
                        <Col sm="1" xs="1"><FaFacebook /> </Col>
                        <Col sm ="11" xs="11"><a href="https://www.facebook.com/InteraidPharmacy" target="_blank">Interaid Pharmacy</a></Col>
                    </Row>
                </p>

                <p class="thin-p">
                    <Row>
                        <Col sm="1" xs="1"><FaClock /> </Col>
                        <Col sm ="11" xs="11">Tuesday - Sunday: 10.00am to 9.30pm<br/> (Closed on Monday)</Col>
                    </Row>
                </p>
 
                </Col>
            </Row>
        </Container>
  </Layout>
)

export default Contact

// <Link to="/page-2/">Go to page 2</Link>

   // <IconContext.Provider value={{ color: "blue"}}>
   // </IconContext.Provider>